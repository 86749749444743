import { useState } from 'react';
import { Flex } from 'glints-aries';
import { Icon, Typography } from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';

import * as Styled from './styled.sc';

export const UnderDevelopment = () => {
  const [clicked, setClicked] = useState(false);
  const [clickCount, setClickCount] = useState(0);
  const [animationDuration, setAnimationDuration] = useState(2000);

  const handleSpeedUpClick = () => {
    setClicked(!clicked);
    setClickCount((prev) => prev + 1);
    setAnimationDuration((prev) => Math.max(400, prev - 400));
  };

  const isBeepEnd = clickCount > 5;

  return (
    <Styled.Container>
      <Styled.Title>
        <Typography as="div" variant="subtitle1" color={Neutral.B18}>
          Hang tight!
        </Typography>
        <Typography as="div" variant="subtitle1" color={Neutral.B18}>
          This page is under development
        </Typography>
      </Styled.Title>

      <Styled.UnderDevelopmentAnimation animationDuration={animationDuration} />

      <Styled.Text fadeIn={clickCount >= 5}>
        {!isBeepEnd ? (
          <>
            <Typography as="div" variant="subtitle2" color={Neutral.B40}>
              Looking forward to it?
            </Typography>
            <Typography as="div" variant="subtitle2" color={Neutral.B40}>
              Give us a beep-beep, and we&apos;ll step on the gas
            </Typography>
            <Typography as="div" variant="subtitle2" color={Neutral.B40}>
              to speed things up!
            </Typography>
          </>
        ) : (
          <>
            <Typography as="div" variant="subtitle2" color={Neutral.B40}>
              Thank you for your enthusiastic beep-beep!
            </Typography>
            <Typography as="div" variant="subtitle2" color={Neutral.B40}>
              We&apos;re currently operating at full capacity.
            </Typography>
          </>
        )}
      </Styled.Text>

      {isBeepEnd === false && (
        <Flex justifyContent="center" mt={24}>
          <Styled.Button
            onClick={handleSpeedUpClick}
            clicked={clicked}
            icon={<Icon name="ri-megaphone-fill" />}
          >
            Beep-Beep!
          </Styled.Button>
        </Flex>
      )}
    </Styled.Container>
  );
};
