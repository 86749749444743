import { useState } from 'react';
import { Typography } from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';

import {
  MANAGED_TALENTS_TABLE_DEFAULT_FILTERED_INFO,
  MANAGED_TALENTS_TABLE_KEY,
} from './components/ManagedTalentsTable/constants';
import { ManagedTalentsTable } from './components/ManagedTalentsTable/ManagedTalentsTable';
import { PAGE_SIZE } from './constants';
import { getGraphqlClient } from '@/clients/graphql';
import {
  type TableFilters,
  type TableOnChange,
} from '@/components/atoms/GlintsAntdTable/types';
import {
  type EmploymentStatus,
  type GetAllHubbersQuery,
  type GetCompanyHubberHubsQuery,
  useGetAllHubbersQuery,
  useGetCompanyHubberHubsQuery,
} from '@/generated/graphql';
import { useGraphqlError } from '@/hooks/useGraphqlError';
import * as PageLayoutStyled from '@/styles/pageLayout.sc';
import { isFilteredInfoEmpty } from '@/utils/glintsAntdTable';
import { generateFilteredHubs } from '@/utils/talentHub';

const ManagedTalentsPage = () => {
  const graphqlClient = getGraphqlClient();
  const [page, setPage] = useState(1);

  const [filteredInfo, setFilteredInfo] = useState<TableFilters>(
    MANAGED_TALENTS_TABLE_DEFAULT_FILTERED_INFO,
  );

  const variables = {
    page,
    pageSize: PAGE_SIZE,
    name: filteredInfo[MANAGED_TALENTS_TABLE_KEY.employee]?.toString() || '',
    status: filteredInfo[
      MANAGED_TALENTS_TABLE_KEY.status
    ] as EmploymentStatus[],
    hub: filteredInfo[MANAGED_TALENTS_TABLE_KEY.talentHub]?.length
      ? generateFilteredHubs(
          filteredInfo[MANAGED_TALENTS_TABLE_KEY.talentHub] as string[],
        )
      : undefined,
  };

  const {
    isLoading: getAllhubbersLoading,
    data: getAllhubbersData,
    error: getAllHubbersError,
  } = useGetAllHubbersQuery<GetAllHubbersQuery, Error>(
    graphqlClient,
    variables,
    {},
  );

  const { data: companyData, error: getCompanyHubberHubsError } =
    useGetCompanyHubberHubsQuery<GetCompanyHubberHubsQuery, Error>(
      graphqlClient,
    );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleTableChange: TableOnChange = (_, filters, __) => {
    setFilteredInfo(filters as TableFilters);
    setPage(1);
  };

  const isFiltering = !isFilteredInfoEmpty(filteredInfo);

  useGraphqlError([getAllHubbersError, getCompanyHubberHubsError]);

  return (
    <PageLayoutStyled.Container>
      <PageLayoutStyled.Header>
        <Typography variant="headline6" as="span" color={Neutral.B18}>
          Employees
        </Typography>
      </PageLayoutStyled.Header>
      <PageLayoutStyled.Body>
        <ManagedTalentsTable
          hubbersData={getAllhubbersData?.hubbers?.data || []}
          companyHubs={companyData?.company.hubberHubs || []}
          loading={getAllhubbersLoading}
          isFiltering={isFiltering}
          filteredProps={{
            filteredInfo,
            setFilteredInfo,
            onReset: () =>
              setFilteredInfo(MANAGED_TALENTS_TABLE_DEFAULT_FILTERED_INFO),
          }}
          paginationProps={{
            currentPage: getAllhubbersData?.hubbers?.pageNo || 1,
            pageSize: getAllhubbersData?.hubbers?.pageSize || 0,
            totalItems: getAllhubbersData?.hubbers?.total || 0,
            onPageChanged: (page: number) => setPage(page),
          }}
          onTableChange={handleTableChange}
        />
      </PageLayoutStyled.Body>
    </PageLayoutStyled.Container>
  );
};

export default ManagedTalentsPage;
