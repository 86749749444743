import { Badge } from 'glints-aries/lib/@next';
import { space8 } from 'glints-aries/lib/@next/utilities/spacing';
import { styled } from 'styled-components';

export const StatusFilterButton = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${space8};
  width: 100%;
`;

export const StatusFilterLeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: ${space8};
  align-items: center;
`;

export const StatusFilterRightContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  gap: ${space8};
  align-items: center;
`;

export const StyledBadge = styled(Badge)`
  &[data-hidden='true'] {
    visibility: hidden;
  }
`;
