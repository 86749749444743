import { Breakpoints, Modal, Typography } from 'glints-aries/lib/@next';
import {
  borderRadius16,
  borderRadiusHalf,
} from 'glints-aries/lib/@next/utilities/borderRadius';
import {
  space4,
  space8,
  space16,
  space24,
  space32,
  space40,
} from 'glints-aries/lib/@next/utilities/spacing';
import { styled } from 'styled-components';

const CUSTOM_PHONE_INPUT_BREAKPOINT = '450px';

export const CardContainer = styled.div`
  padding-bottom: ${space24};
`;

export const CardContentContainer = styled.div`
  padding: 20px ${space24} ${space16};
  display: flex;
  overflow-wrap: anywhere;

  @media (max-width: ${Breakpoints.large}) {
    flex-direction: column;
    padding: ${space16} ${space16};
  }
`;

export const LeftSectionContainer = styled.div`
  flex: 1;
  margin-right: ${space32};

  @media (max-width: ${Breakpoints.large}) {
    margin-right: 0;
  }
`;

export const RightSectionContainer = styled.div`
  flex: 1;
`;

export const StyledImageContainer = styled.div`
  margin-right: 48px;
  margin-top: ${space8};
`;

export const StyledImage = styled.img`
  width: 120px;
  height: 120px;
  border-radius: ${borderRadius16};
  -webkit-box-reflect: below 4px
    linear-gradient(to bottom, rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0.3));

  @media (max-width: ${Breakpoints.large}) {
    margin-bottom: ${space24};
    margin-top: 0px;
  }
`;

export const StyledCircleImage = styled.img`
  width: 120px;
  height: 120px;
  border-radius: ${borderRadiusHalf};
`;

export const RowFlexContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: ${Breakpoints.large}) {
    flex-direction: column;
  }
`;

export const ColumnFlexContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const CardHeaderLoadingContainer = styled.div`
  ul {
    margin-block-start: 0px;
    margin-block-end: 0px;
  }
`;

export const StyledLabel = styled(Typography)`
  margin-bottom: ${space4};
  margin-top: ${space8};
`;

export const StyledValue = styled(Typography)`
  margin-top: 0;
  margin-bottom: ${space16};
`;

export const FormLeftSection = styled(LeftSectionContainer)`
  margin-right: ${space40};
  margin-bottom: ${space8};

  @media (max-width: ${Breakpoints.large}) {
    margin-bottom: 0;
    margin-right: 0;
  }
`;

export const PhoneInputFieldContainer = styled.div`
  margin-bottom: ${space8};
  height: 86px;
  display: flex;
  flex-direction: column;
`;

export const PhoneInputContainer = styled.div`
  display: flex;
  gap: ${space8};

  @media (max-width: ${CUSTOM_PHONE_INPUT_BREAKPOINT}) {
    display: grid;
    grid-template-areas:
      'phone-select phone-select'
      'phone-number phone-ext';
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
`;

export const StyledSelectContainer = styled.div`
  width: 125px;

  @media (max-width: ${CUSTOM_PHONE_INPUT_BREAKPOINT}) {
    grid-area: phone-select;
    width: 100%;
  }
`;

export const StyledNumberTextInput = styled.div`
  flex-grow: 1;
  width: 100%;

  @media (max-width: ${CUSTOM_PHONE_INPUT_BREAKPOINT}) {
    grid-area: phone-number;
  }
`;

export const StyledExtTextInput = styled.div`
  width: 93px;

  @media (max-width: ${CUSTOM_PHONE_INPUT_BREAKPOINT}) {
    grid-area: phone-ext;
  }
`;

export const ModalHeadingContainer = styled.div`
  overflow-wrap: anywhere;
`;

export const CustomModal = styled(Modal)`
  width: 620px;

  @media (max-width: ${Breakpoints.large}) {
    width: 100vw;
  }
`;
