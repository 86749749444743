import { useRef, useState } from 'react';
import { Box, Flex } from 'glints-aries';
import {
  Badge,
  EmptyState,
  Icon,
  type PaginationProps,
  PlainButton,
  PrimaryButton,
  TextInput,
  Typography,
} from 'glints-aries/lib/@next';
import { Blue, Neutral } from 'glints-aries/lib/@next/utilities/colors';
import { space8 } from 'glints-aries/lib/@next/utilities/spacing';
import { gql } from 'graphql-request';

import {
  MANAGED_TALENTS_TABLE_KEY,
  MANAGED_TALENTS_TABLE_TITLE,
} from './constants';
import * as Styled from './styled.sc';
import { ReactComponent as NebulaSVG } from '@/assets/images/nebula.svg';
import { GlintsAntdTable } from '@/components/atoms/GlintsAntdTable/GlintsAntdTable';
import {
  type TableColumns,
  type TableFilters,
  type TableOnChange,
} from '@/components/atoms/GlintsAntdTable/types';
import { type HubCode } from '@/components/atoms/HubLocationText/constants';
import { HubLocationText } from '@/components/atoms/HubLocationText/HubLocationText';
import { statusMapping } from '@/components/atoms/StatusFilterSelect/constants';
import { FormattedDate } from '@/components/FormattedDate/FormattedDate';
import { useHubberDetailsSideSheet } from '@/components/organisms/SideSheets/HubberDetailsSideSheet/context';
import { TablePagination } from '@/components/TablePagination/TablePagination';
import { EMPLOYMENT_STATUS_FILTERS_OPTIONS } from '@/constants/employeeStatus';
import {
  EmploymentStatus,
  EmploymentType,
  type Hubber,
  type ManagedTalentsHubberFragment,
} from '@/generated/graphql';
import { useAntdTableScrollbar } from '@/hooks/useAntdTableScrollbar';
import { computeTenure } from '@/utils/date';
import { generateHubFiltersOptions } from '@/utils/talentHub';

export interface ManagedTalentsTableProps {
  hubbersData: ManagedTalentsHubberFragment[];
  companyHubs: string[];
  loading: boolean;
  isFiltering: boolean;
  filteredProps: {
    filteredInfo: TableFilters;
    setFilteredInfo: React.Dispatch<React.SetStateAction<TableFilters>>;
    onReset: () => void;
  };
  paginationProps: PaginationProps;
  onTableChange: TableOnChange;
}

export const ManagedTalentsTable = ({
  hubbersData,
  companyHubs,
  loading,
  isFiltering,
  filteredProps,
  paginationProps,
  onTableChange,
}: ManagedTalentsTableProps) => {
  const { showHubberSideSheet } = useHubberDetailsSideSheet();
  const { isTableDefaultScrollbarXHidden } = useAntdTableScrollbar();

  const [searchNameText, setSearchNameText] = useState<string>('');
  const searchNameInput = useRef<HTMLInputElement>(null);

  const renderStatusBadge = ({ status }: Pick<Hubber, 'status'>) => {
    const badgeStatus =
      status === EmploymentStatus.Active ? 'success' : 'neutral';
    return <Badge status={badgeStatus}>{statusMapping[status]}</Badge>;
  };

  const renderContractTypeBadge = ({
    employmentType,
    contractType: contract,
  }: Pick<Hubber, 'employmentType' | 'contractType'>) => {
    if (employmentType === EmploymentType.Permanent) {
      return <Badge status="information">Permanent</Badge>;
    } else if (employmentType === EmploymentType.Contract) {
      return <Badge status="neutral">{contract}</Badge>;
    }
  };

  const emptyState = (
    <EmptyState
      title="No Employees"
      description={
        <>
          <div>
            We can&apos;t find any employees associated with your company in our
            system.
          </div>
          <div>
            If it appears to be an error, please contact your Account Manager.
          </div>
        </>
      }
      image={<NebulaSVG />}
    />
  );

  const noMatchingResultsState = (
    <Box py={64}>
      <EmptyState
        title="No Matching Results"
        description="No results were found based on your search keywords or filtering conditions."
        basicButtonAction={{
          label: 'Reset',
          onClick: () => {
            setSearchNameText('');
            filteredProps.onReset();
          },
        }}
      />
    </Box>
  );

  const columns: TableColumns<ManagedTalentsHubberFragment> = [
    {
      title: MANAGED_TALENTS_TABLE_TITLE[MANAGED_TALENTS_TABLE_KEY.status],
      key: MANAGED_TALENTS_TABLE_KEY.status,
      width: 100,
      render: (record: ManagedTalentsHubberFragment) =>
        renderStatusBadge({ status: record.status }),
      filteredValue:
        filteredProps.filteredInfo[MANAGED_TALENTS_TABLE_KEY.status] || null,
      filters: EMPLOYMENT_STATUS_FILTERS_OPTIONS,
    },
    {
      title: MANAGED_TALENTS_TABLE_TITLE[MANAGED_TALENTS_TABLE_KEY.employee],
      key: MANAGED_TALENTS_TABLE_KEY.employee,
      dataIndex: 'fullName',
      width: 200,
      filteredValue:
        filteredProps.filteredInfo[MANAGED_TALENTS_TABLE_KEY.employee] || null,
      filterIcon: () =>
        filteredProps.filteredInfo[MANAGED_TALENTS_TABLE_KEY.employee] ? (
          <Icon name="ri-search" fill={Blue.S99} height={16} width={16} />
        ) : (
          <Icon name="ri-search" fill={Neutral.B40} height={16} width={16} />
        ),
      filterDropdown: ({ confirm }) => (
        <Box p={16}>
          <Box mb={16}>
            <TextInput
              inputRef={searchNameInput}
              placeholder="Search Name"
              value={searchNameText}
              onChange={(value: string) => setSearchNameText(value)}
            />
          </Box>
          <Flex gap={space8}>
            <PlainButton
              onClick={() => {
                confirm({ closeDropdown: true });
                setSearchNameText('');
                filteredProps.setFilteredInfo({
                  ...filteredProps.filteredInfo,
                  [MANAGED_TALENTS_TABLE_KEY.employee]: null,
                });
              }}
            >
              Clear
            </PlainButton>
            <PrimaryButton
              onClick={() => {
                setSearchNameText(searchNameText);
                filteredProps.setFilteredInfo({
                  ...filteredProps.filteredInfo,
                  [MANAGED_TALENTS_TABLE_KEY.employee]: [searchNameText],
                });
              }}
            >
              Search
            </PrimaryButton>
          </Flex>
        </Box>
      ),
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchNameInput.current?.select(), 100);
        }
        if (!visible) {
          if (searchNameText) {
            setSearchNameText(searchNameText);
            filteredProps.setFilteredInfo({
              ...filteredProps.filteredInfo,
              [MANAGED_TALENTS_TABLE_KEY.employee]: [searchNameText],
            });
          } else {
            filteredProps.setFilteredInfo({
              ...filteredProps.filteredInfo,
              [MANAGED_TALENTS_TABLE_KEY.employee]: null,
            });
          }
          paginationProps.onPageChanged?.(1);
        }
      },
    },
    {
      title: MANAGED_TALENTS_TABLE_TITLE[MANAGED_TALENTS_TABLE_KEY.talentHub],
      key: MANAGED_TALENTS_TABLE_KEY.talentHub,
      width: 150,
      render: (record: ManagedTalentsHubberFragment) => (
        <HubLocationText hubCode={record.hub as HubCode} />
      ),
      filteredValue:
        filteredProps.filteredInfo[MANAGED_TALENTS_TABLE_KEY.talentHub] || null,
      filters: generateHubFiltersOptions(companyHubs),
    },
    {
      title: MANAGED_TALENTS_TABLE_TITLE[MANAGED_TALENTS_TABLE_KEY.jobTitle],
      key: MANAGED_TALENTS_TABLE_KEY.jobTitle,
      dataIndex: 'jobTitle',
      width: 250,
    },
    {
      title:
        MANAGED_TALENTS_TABLE_TITLE[MANAGED_TALENTS_TABLE_KEY.contractType],
      key: MANAGED_TALENTS_TABLE_KEY.contractType,
      width: 300,
      render: (record: ManagedTalentsHubberFragment) =>
        renderContractTypeBadge({
          employmentType: record.employmentType,
          contractType: record.contractType,
        }),
    },
    {
      title:
        MANAGED_TALENTS_TABLE_TITLE[MANAGED_TALENTS_TABLE_KEY.jobStartDate],
      key: MANAGED_TALENTS_TABLE_KEY.jobStartDate,
      width: 150,
      render: (record: ManagedTalentsHubberFragment) => (
        <FormattedDate date={record.joinDate} />
      ),
    },
    {
      title: MANAGED_TALENTS_TABLE_TITLE[MANAGED_TALENTS_TABLE_KEY.jobEndDate],
      key: MANAGED_TALENTS_TABLE_KEY.jobEndDate,
      width: 150,
      render: (record: ManagedTalentsHubberFragment) => (
        <FormattedDate date={record.resignDate} noDataText="Still present" />
      ),
    },
    {
      title: MANAGED_TALENTS_TABLE_TITLE[MANAGED_TALENTS_TABLE_KEY.tenure],
      key: MANAGED_TALENTS_TABLE_KEY.tenure,
      width: 150,
      render: (record: ManagedTalentsHubberFragment) => {
        const { tenureYears, tenureMonths } = computeTenure({
          startDate: record.joinDate,
          endDate: record.resignDate,
        });
        return (
          <Typography as="span" variant="subtitle2">
            {tenureYears} yr {tenureMonths} mo
          </Typography>
        );
      },
    },
    {
      title:
        MANAGED_TALENTS_TABLE_TITLE[
          MANAGED_TALENTS_TABLE_KEY.contractExpiryDate
        ],
      key: MANAGED_TALENTS_TABLE_KEY.contractExpiryDate,
      width: 200,
      render: (record: ManagedTalentsHubberFragment) => (
        <FormattedDate date={record.contractEndDate} noDataText="N/A" />
      ),
    },
    {
      title:
        MANAGED_TALENTS_TABLE_TITLE[MANAGED_TALENTS_TABLE_KEY.reportingManager],
      key: MANAGED_TALENTS_TABLE_KEY.reportingManager,
      width: 230,
      render: (record: ManagedTalentsHubberFragment) => (
        <>
          {record.directManager[0] ? (
            record.directManager[0].name
          ) : (
            <Typography variant="subtitle2" color={Neutral.B68}>
              Unassigned
            </Typography>
          )}
        </>
      ),
    },
  ];

  return (
    <Styled.TableContainer>
      <GlintsAntdTable
        columns={columns}
        dataSource={hubbersData}
        onRow={(record) => ({
          onClick: () => {
            showHubberSideSheet({
              hubberData: record,
            });
          },
        })}
        onChange={onTableChange}
        loading={loading}
        pagination={false}
        emptyState={
          hubbersData?.length === 0 && isFiltering
            ? noMatchingResultsState
            : emptyState
        }
        scroll={{ x: 'max-content' }}
        hideDefaultScrollbarX={isTableDefaultScrollbarXHidden}
        sticky={{
          offsetHeader: 0,
          offsetScroll: 64,
        }}
        height="100%"
      />
      {!loading && hubbersData?.length > 0 && (
        <Styled.TablePaginationContainer>
          <TablePagination
            currentPage={paginationProps.currentPage}
            pageSize={paginationProps.pageSize}
            totalItems={paginationProps.totalItems}
            onPageChanged={paginationProps.onPageChanged}
          />
        </Styled.TablePaginationContainer>
      )}
    </Styled.TableContainer>
  );
};

ManagedTalentsTable.fragments = {
  hubber: gql`
    fragment ManagedTalentsHubber on Hubber {
      id
      fullName
      hub
      jobTitle
      joinDate
      contractEndDate
      resignDate
      employmentType
      contractType
      status
      email
      phoneNumber
      directManager {
        id
        name
      }
    }
  `,
};
