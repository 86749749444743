import { differenceInMinutes } from 'date-fns';
import {
  Badge,
  EmptyState,
  Icon,
  SkeletonImageSquare,
  Typography,
} from 'glints-aries/lib/@next';
import { DataTable, type TableHeading } from 'glints-aries/lib/@next/DataTable';
import { type IconNames } from 'glints-aries/lib/@next/Icon/icons/icons';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';

import { ReactComponent as NebulaSVG } from '../../../../assets/images/nebula.svg';
import { FormattedDate } from '../../../../components/FormattedDate/FormattedDate';
import { SortOrder } from '../../../../generated/graphql';
import { type TimeZone } from '../../../../utils/timeZone';
import {
  attendanceStatusBadgeMapping,
  attendanceStatusText,
} from '../../constants';
import { type Attendance, type DailyLogTableProps } from '../../interfaces';
import {
  DateTableHeaderContainer,
  HoursContainer,
  LoggedWorkHoursContainer,
  MinutesContainer,
  SortButton,
  TableContainer,
} from './DailyLogTableStyle';

export const DailyLogTable = ({
  attendanceLogs,
  handleResetFiltersClick,
  hub,
  loading,
  onSort,
  sortOrder,
}: DailyLogTableProps) => {
  const sortDirection: Record<SortOrder, Partial<IconNames>> = {
    [SortOrder.Asc]: 'ri-arrow-up-line',
    [SortOrder.Desc]: 'ri-arrow-down-line',
  };

  const headings: TableHeading[] = [
    {
      title: (
        <DateTableHeaderContainer>
          <SortButton onClick={onSort}>
            <Icon name={sortDirection[sortOrder]} height="16px" />
          </SortButton>
          <Typography variant="caption" color={Neutral.B18} as="span">
            Date
          </Typography>
        </DateTableHeaderContainer>
      ),
    },
    { title: 'Status' },
    { title: 'Clock In' },
    { title: 'Clock Out' },
    { title: 'Total Logged Hours' },
  ];

  const totalLoggedHoursCell = ({
    clockIn,
    clockOut,
  }: Pick<Attendance, 'clockIn' | 'clockOut'>) => {
    if (!clockIn || !clockOut) {
      return (
        <Typography color={Neutral.B85} as="span" variant="subtitle2">
          N/A
        </Typography>
      );
    }

    const diffInMins = differenceInMinutes(
      new Date(clockOut),
      new Date(clockIn),
    );

    const hours = Math.floor(diffInMins / 60);
    const remainingMinutes = diffInMins % 60;

    return (
      <LoggedWorkHoursContainer>
        <HoursContainer>
          <Typography
            color={Neutral.B18}
            as="span"
            variant="subtitle2"
          >{`${hours} `}</Typography>
          <Typography color={Neutral.B40} as="span" variant="overline">
            h
          </Typography>
        </HoursContainer>
        <MinutesContainer>
          <Typography
            color={Neutral.B18}
            as="span"
            variant="subtitle2"
          >{`${remainingMinutes} `}</Typography>
          <Typography color={Neutral.B40} as="span" variant="overline">
            m
          </Typography>
        </MinutesContainer>
      </LoggedWorkHoursContainer>
    );
  };

  const rowMarkup = attendanceLogs?.map((log: Attendance, index: number) => (
    <DataTable.Row key={index}>
      <DataTable.Cell verticalAlign="center" noWrap={true}>
        <FormattedDate
          pattern={{
            weekday: 'short',
            month: 'short',
            day: '2-digit',
            year: 'numeric',
          }}
          date={log.date}
          timeZone={hub as TimeZone}
        />
      </DataTable.Cell>
      <DataTable.Cell verticalAlign="center" noWrap={true}>
        {
          <Badge status={attendanceStatusBadgeMapping[log.status]}>
            {attendanceStatusText[log.status]}
          </Badge>
        }
      </DataTable.Cell>
      <DataTable.Cell verticalAlign="center">
        <FormattedDate
          date={log.clockIn}
          timeZone={hub as TimeZone}
          pattern={{ hour: '2-digit', minute: '2-digit', hour12: false }}
          noDataText="N/A"
        />
      </DataTable.Cell>
      <DataTable.Cell verticalAlign="center">
        <FormattedDate
          date={log.clockOut}
          timeZone={hub as TimeZone}
          pattern={{ hour: '2-digit', minute: '2-digit', hour12: false }}
          noDataText="N/A"
        />
      </DataTable.Cell>
      <DataTable.Cell verticalAlign="center">
        {totalLoggedHoursCell({ clockIn: log.clockIn, clockOut: log.clockOut })}
      </DataTable.Cell>
    </DataTable.Row>
  ));

  const emptyState = (
    <EmptyState
      title="No Logging Data"
      description="No results were found based on current filtering conditions."
      image={<NebulaSVG />}
      basicButtonAction={{
        label: 'Reset',
        onClick: handleResetFiltersClick,
      }}
    />
  );

  const loadingRow = [...Array(20).keys()].map((n) => (
    <DataTable.Row id={`loading-row-${n}`} key={n} position={n}>
      {[...Array(5).keys()].map((n) => (
        <DataTable.Cell key={`loading-row-cell-${n}`}>
          <SkeletonImageSquare height="24px" width="100%" />
        </DataTable.Cell>
      ))}
    </DataTable.Row>
  ));

  return (
    <TableContainer>
      <DataTable
        headings={headings}
        emptyState={emptyState}
        className="daily-log-table"
      >
        {loading ? loadingRow : rowMarkup}
      </DataTable>
    </TableContainer>
  );
};
