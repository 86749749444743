import { type ColumnFilterItem } from 'antd/es/table/interface';

import { EmploymentStatus } from '@/generated/graphql';

export const EMPLOYMENT_STATUS_LABEL = {
  [EmploymentStatus.Active]: 'Active',
  [EmploymentStatus.ResignedOrTerminated]: 'Inactive',
  [EmploymentStatus.ContractEnded]: 'Contract Expired',
} as const;

export const EMPLOYMENT_STATUS_FILTERS_OPTIONS: ColumnFilterItem[] = [
  {
    text: EMPLOYMENT_STATUS_LABEL[EmploymentStatus.Active],
    value: EmploymentStatus.Active,
  },
  {
    text: EMPLOYMENT_STATUS_LABEL[EmploymentStatus.ContractEnded],
    value: EmploymentStatus.ContractEnded,
  },
  {
    text: EMPLOYMENT_STATUS_LABEL[EmploymentStatus.ResignedOrTerminated],
    value: EmploymentStatus.ResignedOrTerminated,
  },
];
