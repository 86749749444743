import { Table, type TableProps } from 'antd';

import { defaultPaginationSettings } from './constants';
import { renderSkeletonColumns, renderSkeletonDataSource } from './helpers';
import * as Styled from './styled.sc';

export interface GlintsAntdTableProps {
  columns: NonNullable<TableProps['columns']>;
  dataSource: TableProps['dataSource'];
  rowKey?: TableProps['rowKey'];
  loading?: TableProps['loading'];
  bordered?: TableProps['bordered'];
  sticky?: TableProps['sticky'];
  size?: TableProps['size'];
  scroll?: TableProps['scroll'];
  rowSelection?: TableProps['rowSelection'];
  pagination?: TableProps['pagination'];
  emptyState?: React.ReactNode;
  height?: string;
  hideDefaultScrollbarX?: boolean;
  hideTableBorderBottom?: boolean;
  onRow?: TableProps['onRow'];
  onChange?: TableProps['onChange'];
}

export const GlintsAntdTable = ({
  columns,
  dataSource,
  rowKey,
  loading,
  bordered,
  sticky,
  size,
  scroll,
  rowSelection,
  pagination,
  emptyState,
  height,
  hideDefaultScrollbarX,
  hideTableBorderBottom,
  onRow,
  onChange,
}: GlintsAntdTableProps) => (
  <Styled.GlintsAntdTableContainer
    height={height}
    hideDefaultScrollbarX={hideDefaultScrollbarX}
    hideTableBorderBottom={emptyState !== undefined || hideTableBorderBottom}
  >
    <Table
      columns={loading ? renderSkeletonColumns(columns) : columns}
      dataSource={loading ? renderSkeletonDataSource() : dataSource}
      rowKey={rowKey}
      bordered={bordered}
      sticky={sticky}
      size={size}
      scroll={scroll}
      rowSelection={rowSelection}
      pagination={pagination ?? defaultPaginationSettings}
      locale={{
        emptyText: emptyState,
      }}
      onRow={onRow}
      onChange={onChange}
    />
  </Styled.GlintsAntdTableContainer>
);
