import { useState } from 'react';
import { format, getMonth, getYear, parse } from 'date-fns';
import {
  Alert,
  EmptyState,
  Icon,
  Pagination,
  PrimaryButton,
  Select,
  SkeletonImageSquare,
  TextInput,
} from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import { type ComponentAction } from 'glints-aries/lib/types/componentAction';

import {
  alertContent,
  AlertType,
  JS_MONTH_OFFSET,
  TALENT_OPTIONS,
} from '../constants';
import { type ShowAlertType } from '../interfaces';
import { HubberCard } from './components/HubberCard';
import {
  ExportButtonContainer,
  HubberCardsContainer,
  PaginationContainer,
  SearchInputContainer,
  SelectInputContainer,
  SelectTalentFilterContainer,
  TimeOffBalanceHeader,
} from './TimeOffBalanceTabStyle';
import { ReactComponent as NebulaSVG } from '@/assets/images/nebula.svg';
import { getGraphqlClient } from '@/clients/graphql';
import { StatusFilterSelect } from '@/components/atoms/StatusFilterSelect/StatusFilterSelect';
import { MONTH_YEAR_DATE_FORMAT } from '@/constants/date';
import {
  EmploymentStatus,
  type ExportTimeoffBalancesMutation,
  type TimeoffRequestsQuery,
  useExportTimeoffBalancesMutation,
  useTimeoffRequestsQuery,
} from '@/generated/graphql';
import { useDebounce } from '@/hooks/debounce';
import { useGraphqlError } from '@/hooks/useGraphqlError';
import { periodFilterOptions } from '@/modules/AttendanceLog/components/PeriodFilter';

export const TimeOffBalance = () => {
  const graphqlClient = getGraphqlClient();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchValue, setSearchValue] = useState<string>('');
  const [showAlert, setShowAlert] = useState<ShowAlertType>({
    status: undefined,
    content: '',
    shouldShow: false,
  });
  const [filterYearValue, setFilterYearValue] = useState<string>(
    format(new Date(), MONTH_YEAR_DATE_FORMAT),
  );
  const [hideStatusBadge, setHideStatusBadge] = useState(false);
  const [selectedTalent, setSelectedTalent] = useState<string[]>([
    TALENT_OPTIONS[0].value,
  ]);

  const parsedFilterYearValue = parse(
    filterYearValue,
    MONTH_YEAR_DATE_FORMAT,
    new Date(),
  );

  const resetPage = () => {
    setCurrentPage(1);
  };

  const handleSearchChange = (value: string) => {
    setSearchValue(value);
    resetPage();
  };
  const debouncedSearchValue = useDebounce(searchValue, 500);

  const handleTalentSelect = ({ value }: { value: string }) => {
    let updatedSelectedStatus = [];
    if (selectedTalent) {
      if (selectedTalent.includes(value)) {
        updatedSelectedStatus = selectedTalent.filter(
          (option) => option !== value,
        );
        setSelectedTalent(updatedSelectedStatus);
        setCurrentPage(1);
      } else {
        updatedSelectedStatus = [...selectedTalent, value];
        setSelectedTalent(updatedSelectedStatus);
        setCurrentPage(1);
      }
      if (updatedSelectedStatus?.length < TALENT_OPTIONS.length) {
        setHideStatusBadge(false);
      }
    }
  };
  const updateHideStatusBadge = (hide: boolean) => {
    setHideStatusBadge(hide);
  };

  const {
    isLoading: isLoadingExport,
    mutate: exportMutate,
    error: exportTimeOffBalancesError,
  } = useExportTimeoffBalancesMutation<Error, ExportTimeoffBalancesMutation>(
    graphqlClient,
  );

  const handleFilterSelect = ({ value }: { value: string }) => {
    setFilterYearValue(value);
    resetPage();
  };

  const handleExport = () => {
    const exportVariables = {
      year: getYear(parsedFilterYearValue),
      month: getMonth(parsedFilterYearValue) + JS_MONTH_OFFSET,
      name: debouncedSearchValue,
    };
    exportMutate(exportVariables, {
      onSuccess: (data) => {
        setShowAlert({
          status: AlertType.SUCCESS,
          content: alertContent[AlertType.SUCCESS],
          shouldShow: true,
        });

        const linkElement = document.createElement('a');
        linkElement.href = data?.exportTimeoffBalances?.fileUrl;
        document.body.appendChild(linkElement);
        linkElement.click();
        document.body.removeChild(linkElement);
      },
      onError: () => {
        setShowAlert({
          status: AlertType.ERROR,
          content: alertContent[AlertType.ERROR],
          shouldShow: true,
        });
      },
    });
  };

  const variables = {
    page: currentPage,
    pageSize: 10,
    name: debouncedSearchValue,
    status: selectedTalent as EmploymentStatus[],
    year: getYear(parsedFilterYearValue),
    month: getMonth(parsedFilterYearValue) + JS_MONTH_OFFSET,
  };
  const {
    data,
    isLoading,
    error: timeOffRequestsError,
  } = useTimeoffRequestsQuery<TimeoffRequestsQuery, Error>(
    graphqlClient,
    variables,
    {},
  );

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const onResetAll = () => {
    setSearchValue('');
    resetPage();
    setSelectedTalent([]);
    setFilterYearValue(format(new Date(), MONTH_YEAR_DATE_FORMAT));
  };

  const emptyStateAction: ComponentAction = {
    label: 'Reset',
    onClick: () => {
      setSearchValue('');
    },
  };

  useGraphqlError([timeOffRequestsError, exportTimeOffBalancesError]);

  return (
    <>
      <TimeOffBalanceHeader>
        <SearchInputContainer>
          <TextInput
            value={searchValue}
            prefix={<Icon name="ri-search" />}
            placeholder="Search for Name"
            onChange={handleSearchChange}
          />
        </SearchInputContainer>
        <SelectInputContainer>
          <Select
            prefix={
              <Icon
                name="ri-calendar-event-line"
                height="20px"
                fill={Neutral.B40}
              />
            }
            options={periodFilterOptions()}
            onSelect={handleFilterSelect}
            selectedValues={[filterYearValue]}
            width="100%"
            listHeight={248}
          />
        </SelectInputContainer>
        <SelectTalentFilterContainer>
          <StatusFilterSelect
            selectedStatus={selectedTalent}
            handleSelectStatus={handleTalentSelect}
            updateHideStatusBadge={updateHideStatusBadge}
            hideBadge={hideStatusBadge}
          />
        </SelectTalentFilterContainer>
        <ExportButtonContainer>
          <PrimaryButton
            icon={<Icon name="ri-download-line" />}
            type="button"
            onClick={handleExport}
            loading={isLoadingExport}
          >
            Export
          </PrimaryButton>
        </ExportButtonContainer>
      </TimeOffBalanceHeader>
      {!isLoading && data?.hubbers?.data?.length === 0 ? (
        <EmptyState
          title="No Balance Data"
          description="No results were found based on current filtering conditions"
          image={<NebulaSVG />}
          basicButtonAction={{
            label: 'Clear All Filters',
            onClick: onResetAll,
          }}
        />
      ) : (
        <>
          <HubberCardsContainer>
            {isLoading && (
              <HubberCardsContainer>
                {[...Array(3).keys()].map((n) => (
                  <SkeletonImageSquare height="300px" width="100%" key={n} />
                ))}
              </HubberCardsContainer>
            )}
            {!isLoading && data?.hubbers?.data.length === 0 && (
              <EmptyState
                title="No Matching Results"
                description="No results were found based on your search keywords or filtering conditions."
                basicButtonAction={emptyStateAction}
              />
            )}
            {!isLoading &&
              data?.hubbers?.data.map((hubber) => (
                <HubberCard
                  fullName={hubber.fullName}
                  active={hubber.status === EmploymentStatus.Active}
                  joinDate={hubber.joinDate}
                  contractEndDate={hubber.contractEndDate}
                  resignDate={hubber.resignDate}
                  timeOffBalance={hubber.timeoffBalance}
                  publicHoliday={hubber.publicHoliday}
                  year={filterYearValue.slice(4)}
                  key={hubber.id}
                />
              ))}
          </HubberCardsContainer>
          {!isLoading && data?.hubbers?.data.length !== 0 && (
            <PaginationContainer>
              <Pagination
                currentPage={currentPage}
                pageSize={data?.hubbers?.pageSize || 0}
                totalItems={data?.hubbers?.total || 0}
                onPageChanged={handlePageChange}
              />
            </PaginationContainer>
          )}
          <Alert
            show={showAlert.shouldShow}
            onDismissed={() =>
              setShowAlert({
                status: undefined,
                content: '',
                shouldShow: false,
              })
            }
            content={showAlert.content}
            status={showAlert.status}
          />
        </>
      )}
    </>
  );
};
