import { Button } from 'glints-aries/lib/@next';
import { space24 } from 'glints-aries/lib/@next/utilities/spacing';
import { styled } from 'styled-components';

export const HEADER_HEIGHT = '158px';

export const MainContainer = styled.div`
  height: calc(100% - ${HEADER_HEIGHT});

  ul {
    gap: 0px;
    padding-inline-start: 0px;
  }

  li {
    padding: 0 2px;
  }

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledPageTitleContainer = styled.div`
  padding-bottom: 4px;
`;

export const AddContactButton = styled(Button)`
  margin-bottom: ${space24};
`;

export const TabsContainer = styled.div`
  height: 100%;

  .tabs-container {
    height: 100%;

    .tab-item-content {
      height: 100%;
    }
  }
`;

export const TabContentContainer = styled.div<{ disableMarginTop?: boolean }>`
  margin-top: ${({ disableMarginTop }) => (disableMarginTop ? '0' : space24)};
`;
