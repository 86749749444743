import { useEffect, useMemo, useState } from 'react';
import { Tabs, Typography } from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import {
  ALLOWED_PAYMENT_COUNTRIES,
  PERMITTED_EGIRO_ROLES,
} from '../InvoicePayments/constants';
import {
  MainContainer,
  StyledPageTitleContainer,
  TabContentContainer,
  TabsContainer,
} from './CompanyProfileStyle';
import {
  type CompanyProfileTabName,
  companyProfileTabNames,
  companyProfileTabsInOrder,
  companyProfileTabUrls,
  createTabConfiguration,
  PERMITTED_MANAGE_USERS_ROLE,
} from './constants';
import { getCompanyProfileTabFromPathname } from './helpers';
import { useAuthContext } from '@/components/particles/AuthInfoProvider/AuthInfoProvider';
import { useFeatureFlagContext } from '@/components/particles/FeatureFlagProvider/FeatureFlagProvider';
import { UNLEASH_FLAG_NAME } from '@/constants/constants';
import { useScreen } from '@/hooks/screen';

const CompanyProfilePage = ({
  activeTabName = companyProfileTabNames.COMPANY_DETAILS,
}: {
  activeTabName?: CompanyProfileTabName;
}) => {
  const featureFlagContext = useFeatureFlagContext();
  const { useFlagEnabled } = featureFlagContext;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { userInfo } = useAuthContext();
  const isLargeScreen = useScreen();
  const { isValidTab, tabPathUrl } = getCompanyProfileTabFromPathname(pathname);
  const currentUserRole = userInfo?.contact?.roles[0].id;
  const iseGIROShown =
    useFlagEnabled(UNLEASH_FLAG_NAME.mtseGIRO) &&
    userInfo?.company?.billingAddress?.country &&
    ALLOWED_PAYMENT_COUNTRIES.includes(
      userInfo.company.billingAddress.country,
    ) &&
    PERMITTED_EGIRO_ROLES.includes(currentUserRole);

  const permittedTabNames: CompanyProfileTabName[] = useMemo(() => {
    let initialTabNames: CompanyProfileTabName[] = [
      companyProfileTabNames.COMPANY_DETAILS,
      companyProfileTabNames.REPORTING_LINES,
    ];

    if (PERMITTED_MANAGE_USERS_ROLE.includes(currentUserRole)) {
      initialTabNames.push(companyProfileTabNames.ROLES_AND_PERMISSIONS);
    }

    if (iseGIROShown) {
      initialTabNames.push(companyProfileTabNames.AUTO_PAYMENT);
    }

    initialTabNames = initialTabNames.sort(
      (a, b) =>
        companyProfileTabsInOrder.indexOf(a) -
        companyProfileTabsInOrder.indexOf(b),
    );

    return initialTabNames;
  }, [currentUserRole, iseGIROShown]);

  const { tabs, tabIndexMap, indexToTabNameMap, tabUrltoTabIndexMap } =
    createTabConfiguration(permittedTabNames);

  const [tabSelected, setTabSelected] = useState<number>(
    tabIndexMap[activeTabName] ??
      tabIndexMap[companyProfileTabNames.COMPANY_DETAILS],
  );

  const handleTabSelect = (index: number) => {
    setTabSelected(index);
    navigate(companyProfileTabUrls[indexToTabNameMap[index]]);
  };

  useEffect(() => {
    if (!isValidTab) {
      navigate('/404');
    }

    if (isValidTab && tabPathUrl) {
      setTabSelected(tabUrltoTabIndexMap[tabPathUrl]);
    }
  }, [isValidTab, navigate, tabPathUrl, tabUrltoTabIndexMap]);

  return (
    <MainContainer>
      <StyledPageTitleContainer>
        <Typography as="div" variant="headline6" color={Neutral.B18}>
          Company Settings
        </Typography>
      </StyledPageTitleContainer>
      <TabsContainer>
        <Tabs
          tabs={tabs}
          selected={tabSelected}
          onSelected={handleTabSelect}
          fitted={!isLargeScreen}
          disabledScrollToTab={true}
        >
          <TabContentContainer
            disableMarginTop={
              indexToTabNameMap[tabSelected] ===
              companyProfileTabNames.REPORTING_LINES
            }
          >
            <Outlet />
          </TabContentContainer>
        </Tabs>
      </TabsContainer>
    </MainContainer>
  );
};

export default CompanyProfilePage;
