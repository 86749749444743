import { EmploymentStatus } from '@/generated/graphql';

export const statusMapping = {
  [EmploymentStatus.Active]: 'Active',
  [EmploymentStatus.ResignedOrTerminated]: 'Inactive',
  [EmploymentStatus.ContractEnded]: 'Contract Expired',
};

type OptionType = {
  label: string;
  value: EmploymentStatus;
};

export const statusFilterOptions: OptionType[] = [
  { label: 'Active', value: EmploymentStatus.Active },
  { label: 'Contract Expired', value: EmploymentStatus.ContractEnded },
  { label: 'Inactive', value: EmploymentStatus.ResignedOrTerminated },
];
