import { EmploymentStatus } from '@/generated/graphql';

export const MANAGED_TALENTS_TABLE_KEY = {
  status: 'status',
  employee: 'employee',
  talentHub: 'talentHub',
  jobTitle: 'jobTitle',
  contractType: 'contractType',
  jobStartDate: 'jobStartDate',
  jobEndDate: 'jobEndDate',
  tenure: 'tenure',
  contractExpiryDate: 'contractExpiryDate',
  reportingManager: 'reportingManager',
} as const;

export const MANAGED_TALENTS_TABLE_TITLE = {
  [MANAGED_TALENTS_TABLE_KEY.status]: 'Status',
  [MANAGED_TALENTS_TABLE_KEY.employee]: 'Name',
  [MANAGED_TALENTS_TABLE_KEY.talentHub]: 'TalentHub',
  [MANAGED_TALENTS_TABLE_KEY.jobTitle]: 'Job Title',
  [MANAGED_TALENTS_TABLE_KEY.contractType]: 'Contract Type',
  [MANAGED_TALENTS_TABLE_KEY.jobStartDate]: 'Job Start Date',
  [MANAGED_TALENTS_TABLE_KEY.jobEndDate]: 'Job End Date',
  [MANAGED_TALENTS_TABLE_KEY.tenure]: 'Tenure',
  [MANAGED_TALENTS_TABLE_KEY.contractExpiryDate]: 'Contract Expiry Date',
  [MANAGED_TALENTS_TABLE_KEY.reportingManager]: 'Reporting Manager',
} as const;

export const MANAGED_TALENTS_TABLE_DEFAULT_FILTERED_INFO = {
  [MANAGED_TALENTS_TABLE_KEY.status]: [EmploymentStatus.Active],
  [MANAGED_TALENTS_TABLE_KEY.employee]: null,
  [MANAGED_TALENTS_TABLE_KEY.talentHub]: null,
};
